import React, { useState } from 'react';
import ProductionTeam from "../../swarropalloy/new/istockphoto-1223930395-2048x2048.jpg";
import "./Contact.css";



function Contact() {

  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    city: '',
    mobile: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const response = await fetch('https://example.com/send-email', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       to: 'siddhikatakdond12@gmail.com',
  //       subject: 'New Contact Form Submission',
  //       text: `Name: ${formData.fullname}\nEmail: ${formData.email}\nCity: ${formData.city}\nMobile: ${formData.mobile}\nMessage: ${formData.message}`,
  //     }),
  //   });

  //   if (response.ok) {
  //     alert('Message sent successfully!');
  //   } else {
  //     alert('Failed to send message.');
  //   }
  // };

  return (
    // <div className='' style={{height:'600px'}}>
    //     <section className="page-header page-header-modern page-header-lg  m-0 py-5 about-img">
    //       <div className="container">
    //         <div
    //           className="row align-items-center"
    //           style={{ marginTop: "220px" }}
    //         >
    //           <div className="col-md-8 mb-4 mb-md-0">
    //             <h1 className="text-white font-weight-bold mb-0 ">About Us</h1>
    //           </div>
    //           <div className="col-md-4 ">
    //             <ul className="breadcrumb justify-content-md-end text-white text-3-5">
    //               <li>
    //                 <a href="/" className="text-white text-decoration-none">
    //                   HOME
    //                 </a>
    //               </li>
    //               <li className="active">About Us</li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   <h1 className=''>
    //  Contact

    //   </h1>
    //   </div>
    <>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n\t\t.error {\n\t\t\tcolor: red;\n\t\t}\n\n\t\t.valid {\n\t\t\tcolor: green;\n\n\t\t}\n\n\t\t.panel1 {\n\t\t\tmargin-bottom: 20px;\n\t\t\tbackground-color: #fff;\n\t\t\tborder: 1px solid transparent;\n\t\t\tborder-radius: 4px;\n\t\t}\n\n\t\t.panel-primary>.panel-heading {\n\t\t\tcolor: #fff;\n\t\t\tbackground-color: #2B82C5;\n\t\t\tborder-color: #2B82C5;\n\t\t}\n\n\t\t.panel-heading {\n\t\t\tpadding: 10px 15px;\n\t\t\tborder-top-left-radius: 3px;\n\t\t\tborder-top-right-radius: 3px;\n\t\t}\n\n\t\t.panel-body {\n\t\t\tpadding: 15px;\n\t\t\tborder: 1px solid #2B82C5;\n\t\t}\n\n\t\t.form-control {\n\t\t\tdisplay: block;\n\t\t\twidth: 100%;\n\t\t\tpadding: .375rem .75rem;\n\t\t\tfont-size: 1rem;\n\t\t\tline-height: 1.5;\n\t\t\tcolor: #495057;\n\t\t\tbackground-color: #fff;\n\t\t\tbackground-clip: padding-box;\n\t\t\tborder: 1px solid #ced4da;\n\t\t\tborder-radius: .25rem;\n\t\t\ttransition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;\n\t\t\tmargin-bottom: 20px;\n\t\t}\n\n\t\t.btn1 {\n\t\t\tbackground: #2B82C5;\n\t\t\tborder: #2B82C5;\n\t\t}\n\n\t\t.btn1:hover {\n\t\t\tbackground: #2B82C5;\n\t\t\tborder: #2B82C5;\n\t\t}\n\t",
        }}
      />
      <link
        rel="stylesheet"
        href="agnis_webkit/captcha/css/jquery.realperson.css"
      />
      <div className="body">
        <div role="main" className="main">
          <div className="" style={{ height: "330px" }}>
            <section className="page-header page-header-modern page-header-lg  m-0 pt-5 about-img">
              <div className="container">
                <div
                  className="row align-items-center"
                  style={{ marginTop: "220px" }}
                >
                  <div className="col-md-8 mb-4 mb-md-0">
                    <h1 className="text-white font-weight-bold mb-0 ">
                    Contact Us
                    </h1>
                  </div>
                  <div className="col-md-4 ">
                    <ul className="breadcrumb justify-content-md-end text-white text-3-5">
                      <li>
                        <a href="/" className="text-white text-decoration-none">
                          HOME
                        </a>
                      </li>
                      <li className="active">Contact Us</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section className="section bg-transparent section-height-4 border-0 m-0 ">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-xl-6 mb-5 mb-lg-0">
                  <div className="card bg-primary custom-border-radius-1">
                    <div className="card-body pb-5 mb-2 ">
                      <h2 className="text-color-light d-flex font-weight-medium text-3 line-height-2 line-height-sm-1 mb-3 pb-1">
                        Get Connected With Us
                      </h2>
                      <h3 style={{textAlign:"left"}} className="font-weight-bold d-flex  text-color-light text-transform-none text-8 line-height-3 mb-3">
                        SOHAN INDUSTRIES
                      </h3>
                      <p className="text-justify text-color-light text-3 opacity-7 mb-5">
                      <b>Sohan Industries</b> is specialized in graded Cast Iron and Spheroidal Graphite Iron Castings. With years of experience in manufacturing quality products, we are proud to have a highly skilled team committed to excellence.
                      </p>
                      <ul className="list list-unstyled text-color-dark text-3 mb-4 ">
                        <li className="pb-2 d-flex ">
                          <i className="far fa-dot-circle text-color-white mt-2 mx-2" />
                          
                          <a
                            href="# "
                            className="text-color-white text-color-hover-white text-decoration-none "
                            style={{textAlign:"left"}}
                          >                           
                            Plot No.
                            G-40,M.I.D.C.  
                            KAGAL FIVE STAR, 
                            Kolhapur 416216.
                          </a>
                        </li>
                        <li className="pb-2 d-flex">
                          <i className="icons icon-envelope text-color-white mt-2 mx-2" />
                          <a
                            href="mailto:bhushanpowar1999@gmail.com"
                            className="text-color-white text-color-hover-white text-decoration-none mx-2"
                          >
                            {" "}
                            sohanindustriesg40@gmail.com
                          </a>
                        </li>
                        <li className="pb-2 d-flex">
                          <i className="icons icon-phone text-color-white mt-2 mx-2" />
                          <a
                            href="tel:+919921114376"
                            className="text-color-white text-color-hover-white text-decoration-none mx-2"
                          >
                            +919921114376 /{" "}
                          </a>
                          <a
                            href="tel:+919890383622"
                            className="text-color-white text-color-hover-white text-decoration-none"
                          >
                            +919890383622
                          </a>
                        </li>
                      </ul>
                      {/* <div className="overflow-hidden pb-2">
                        <h4 className="text-color-white line-height-3 text-5-5 mb-0">
                          Managing Directors
                        </h4>
                      </div>
                      <ul className="list list-unstyled text-color-white text-4 my-4">
                        <li className="pb-2">
                          <i className="icons icon-phone text-color-white" />{" "}
                          Prasad Janaj :{" "}
                          <a
                            href="tel:+918888861261"
                            className="text-color-white text-color-hover-white text-decoration-none"
                          >
                            +91 888 886 1261
                          </a>
                        </li>
                        <li className="pb-4">
                          <i className="icons icon-phone text-color-white" />{" "}
                          Ketan Shah :{" "}
                          <a
                            href="tel:+919422046044"
                            className="text-color-white text-color-hover-white text-decoration-none"
                          >
                            +91 942 204 6044
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6">
                  <div className="panel1 panel-primary">
                    <div className="panel-heading text-align-left d-flex">Enquiry Form</div>
                    <div className="panel-body">
                      {/* Contact Form */}
                      <form
                        id="contact_form"
                        name="contact_form"
                        // action="https://www.swaroopalloy.com/agnis_ajax_submit.php"
                        // method="post"
                        // onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="col-xs-12 col-md-6">
                            <div className="form-group">
                              <label className="text-align-left d-flex">
                                Name <small >*</small>
                              </label>
                              <input
                                name="fullname"
                                type="text"
                                required="required"
                                className="form-control"
                                id="fullname"
                                placeholder="Enter Name"
                                value={formData.fullname}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <div className="form-group">
                              <label className="text-align-left d-flex">
                                Email <small>*</small>
                              </label>
                              <input
                                name="email"
                                type="email"
                                required
                                className="form-control"
                                id="email"
                                placeholder="Enter Email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12 col-sm-6">
                            <div className="form-group">
                              <label className="text-align-left d-flex">City *</label>
                              <input
                                name="city"
                                type="text"
                                required="required"
                                className="form-control"
                                id="form_subject"
                                placeholder="Enter City"
                                value={formData.city}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6">
                            <div className="form-group">
                              <label className="text-align-left d-flex">Mobile *</label>
                              <input
                                name="mobile"
                                type="text"
                                required="required"
                                className="form-control required"
                                id="mobile"
                                placeholder="Enter Mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-xs-12">
                            <div className="form-group">
                              <label className="text-align-left d-flex">Message *</label>
                              <textarea
                                id="message"
                                name="enquiry[array_name][message]"
                                className="form-control required"
                                rows={5}
                                placeholder="Enter Message"
                                maxLength={255}
                                defaultValue={""}
                                value={formData.message}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-sm-12 col-xs-12">
                            <div className="form-group">
                              <input
                                type="text"
                                id="defaultReal"
                                name="defaultReal"
                                className="form-control"
                                required=""
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="row pt-2">
                          <div className="col-sm-12 col-sm-12">
                            <div className="form-group">
                              <input
                                id="form_botcheck"
                                name="form_botcheck"
                                className="form-control"
                                type="hidden"
                                defaultValue=""
                              />
                              <button
                                type="submit"
                                className="btn btn-success btn1"
                                data-loading-text="Please wait..."
                              >
                                Send your message
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-12 col-xl-12 pt-5">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30577.559319047443!2d74.2470848053384!3d16.667126914885525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc0fe67aaaaaaab%3A0x568c10bd80e051f8!2sSwaroop%20Alloy%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1665482372351!5m2!1sen!2sin"
                    style={{ border: 0, width: "100%", height: 420 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div> */}
              </div>
            </div>
          </section>
         
          {/* <div className="agnis-mobile-footer">
            <div className="auto-container">
              <div className="row clearfix centred">
                <div className="col-3 col-sm-3 padding-top-5">
                  <div className="icon p-2 ">
                    <a href="tel:+918888861261">
                      <img
                        src="sticky-footer-images/phone-call.png"
                        alt="call-icon"
                        className="call-icon"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-6 col-sm-6 padding-top-5">
                  <div
                    className="icon pr-1 fs-5 p-2 btn-p"
                    style={{ background: "#000" }}
                  >
                    <a href="contact.html" className="text-white font-14">
                      Enquiry Now
                    </a>
                  </div>
                </div>
                <div className="col-3 col-sm-3">
                  <div className="icon p-2 ">
                    <a href="https://wa.me/+919422046044" target="_blank">
                      <img
                        src="sticky-footer-images/whatsapp.png"
                        alt="whatsapp-icon"
                        className="whatsapp-icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* LOAD JQUERY */}
      {/* Vendor */}
      {/* Theme Base, Components and Settings */}
      {/* Current Page Vendor and Views */}
      {/* Demo */}
      {/* Theme Custom */}
      {/* Theme Initialization Files */}
      {/* Mirrored from www.swaroopalloy.com/contact.php by HTTrack Website Copier/3.x [XR&CO'2014], Sat, 13 Jul 2024 07:11:00 GMT */}
    </>
  );
}

export default Contact;
