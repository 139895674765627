import React from "react";
import ProductionTeam from '../../swarropalloy/new/istockphoto-1223930395-2048x2048.jpg'
import "./About.css";
import demo from "../../swarropalloy/new/About-Home.png";

function About() {
  return (
    <>
      <div role="main" className="main">
        {/*======================== About Header Start ======================= */}
        <section className="page-header page-header-modern page-header-lg  m-0 py-5 about-img">
          <div className="container">
            <div
              className="row align-items-center"
              style={{ marginTop: "220px" }}
            >
              <div className="col-md-8 mb-4 mb-md-0">
                <h1 className="text-white font-weight-bold mb-0 ">About Us</h1>
              </div>
              <div className="col-md-4 ">
                <ul className="breadcrumb justify-content-md-end text-white text-3-5">
                  <li>
                    <a href="/" className="text-white text-decoration-none">
                      HOME
                    </a>
                  </li>
                  <li className="active">About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*======================== About Header END ======================= */}

         {/* =====================================  About Start  ======================================*/}
         <div className="container my-5 pt-md-4 pt-xl-0">
          <div className="row align-items-center justify-content-center pb-4 mb-5">
            <div className="col-lg-6 pb-sm-4 pb-lg-0 mb-5 mb-lg-0">
              <div className="overflow-hidden text-start">
                <h2
                  className="font-weight-bold text-color-dark line-height-1 mb-0 appear-animation font"
                  data-appear-animation="maskUp"
                  data-appear-animation-delay={300}
                >
                  About Company
                </h2>
              </div>
              <div className="custom-divider divider divider-primary divider-small my-3">
                <hr
                  className="my-0 appear-animation"
                  data-appear-animation="customLineProgressAnim"
                  data-appear-animation-delay={700}
                  style={{ backgroundColor: "rgb(16 78 161)" }}
                />
              </div>
              <p
                className="fw-normal text-black text-3-5 mb-5 appear-animation text-justify font"
                data-appear-animation="fadeInUpShorter"
                data-appear-animation-delay={450}
              >
                {" "}
                At &nbsp;
                <span
                  className="fw-bolder "
                  style={{ color: "rgb(15, 74, 170)" }}
                >
                  SOHAN INDUSTRIES,
                </span>{" "}
                we operate a modern facility spread over 13,500 sq. ft., powered
                by a 300 HP connection. Our dedicated team of 60 employees
                utilizes advanced equipment, including a 300 kg Inductotherm
                furnace and 3 centrifugal casting machines, ensuring precision
                and quality in every process.
                <br />
                We are equipped with 2 core shooters, a 30 kg hanger shot
                blasting machine, and multiple fettling, grinding, and molding
                setups. Our efficient production is further supported by a 15 HP
                screw air compressor, cooling tanks, sand mixers, and
                specialized riser cutting machinery. At SOHAN INDUSTRIES, we are
                committed to delivering top-tier industrial casting solutions.
              </p>
              {/* <p
                className="font-weight-light text-3-5 mb-4 appear-animation text-justify"
                data-appear-animation="fadeInUpShorter"
                data-appear-animation-delay={450}
              >
                SOHAN INDUSTRIES, established in 2022-23, builds on over three
                decades of engineering expertise dating back to 1990.
                Specializing in the manufacture of critical engineering parts
                for hydraulic, automobile, sugar, and tooling industries, we
                have expanded our capabilities with a new casting manufacturing
                unit. Our 13,500 sq. ft. facility, powered by a 300 HP
                connection and staffed by 60 skilled employees, is equipped with
                advanced machinery including Inductotherm furnaces, core
                shooters, centrifugal casting machines, and more to ensure
                high-quality production and customer satisfaction.
              </p> */}
              <div className="d-flex align-items-md-start align-items-sm-center flex-column flex-sm-row ">
                {" "}
                <a
                  href="/"
                  className="btn btn-primary custom-btn-border-radius font-weight-bold text-3 px-5 btn-py-3 me-sm-2 mb-3 mb-sm-0 appear-animation font"
                  data-appear-animation="fadeInRightShorterPlus"
                  data-appear-animation-delay={950}
                  style={{ background: "rgb(16 78 161)" }}
                >
                  READ MORE
                </a>
                <div
                  className="feature-box align-items-center border border-top-0 border-end-0 border-bottom-0 custom-remove-mobile-xs-border-left ms-sm-4 ps-sm-4 appear-animation"
                  data-appear-animation="fadeInRightShorterPlus"
                  data-appear-animation-delay={1200}
                >
                  <div className="feature-box-icon bg-transparent align-items-center text-end ABOUT-Phone">
                    {" "}
                    <i className="icons icon-phone text-6 text-color-dark" />{" "}
                  </div>
                  <div className="feature-box-info line-height-2 ps-1 text-start">
                    {" "}
                    <span className="d-block text-1 font-weight-semibold text-color-default font">
                      CALL US NOW
                    </span>{" "}
                    <strong className="text-4-5">
                      <a
                        href="tel:+919921114376"
                        className="text-color-dark text-color-hover-primary text-decoration-none font"
                      >
                        +91 9921114376
                      </a>
                    </strong>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-10 col-md-9 col-lg-6 ps-lg-5 pe-5 appear-animation"
              data-appear-animation="fadeInRightShorterPlus"
              data-appear-animation-delay={1450}
              data-plugin-options="{'accY': -200}"
              style={{ opacity: 1 }}
            >
              <div className="position-relative">
                <div
                  data-plugin-float-element
                  data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}"
                >
                  {" "}
                  <img
                    // src="swarropalloy/home/about.png"
                    src={demo}
                    className="img-fluid w-100"
                    alt="CI Casting and SG Iron Casting"
                  />{" "}
                </div>
                {/* <div
                  className="position-absolute transform3dxy-n50"
                  style={{ top: "25%", left: "7%" }}
                >
                  <div
                    data-plugin-float-element
                    data-plugin-options="{'startPos': 'top', 'speed': 0.5, 'transition': true, 'transitionDuration': 2000, 'isInsideSVG': false}"
                  
                  >
                    {" "}
                    <img
                      src="swarropalloy/home/about3.png"
                      className="appear-animation"
                      alt="CI Casting and SG Iron Casting"
                      data-appear-animation="fadeInUpShorterPlus"
                      data-appear-animation-delay={1700}
                    />{" "}
                  </div>
                </div>
                <div
                  className="position-absolute transform3dxy-n50 ms-5 pb-5 ms-xl-0"
                  style={{ top: "32%", left: "85%" }}
                >
                  <div
                    data-plugin-float-element
                    data-plugin-options="{'startPos': 'top', 'speed': 1, 'transition': true, 'transitionDuration': 1500, 'isInsideSVG': false}"
                  >
                    {" "}
                    <img
                      src="swarropalloy/home/about2.png"
                      className="appear-animation"
                      alt="CI Casting and SG Iron Casting"
                      data-appear-animation="fadeInRightShorterPlus"
                      data-appear-animation-delay={1900}
                    />{" "}
                  </div>
                </div>
                <div
                  className="position-absolute transform3dxy-n50"
                  style={{ top: "90%", left: "19%" }}
                >
                  <div
                    data-plugin-float-element
                    data-plugin-options="{'startPos': 'top', 'speed': 2, 'transition': true, 'transitionDuration': 2500, 'isInsideSVG': false}"
                  >
                    {" "}
                    <img
                      src="swarropalloy/home/about1.png"
                      className="appear-animation"
                      alt="CI Casting and SG Iron Casting"
                      data-appear-animation="fadeInDownShorterPlus"
                      data-appear-animation-delay={2100}
                    />{" "}
                  </div>
                </div> */}  
              </div>
            </div>
          </div>
        </div>
        {/* =====================================  About END  ======================================*/}


        {/*======================== About SOHAN INDUSTRIES Start ======================= */}
        {/* <section className="section bg-transparent section-height-4 border-0 m-0">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-12 pb-sm-4 pb-lg-0 align-items-center text-start">
                <div className="overflow-hidden">
                  <h2
                    className="font-weight-bold text-color-dark line-height-1 mb-0 appear-animation font"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay={300}
                  >
                    SOHAN INDUSTRIES
                  </h2>
                </div>
                <div className="custom-divider divider divider-primary divider-small my-3">
                  <hr
                    className="my-0 appear-animation"
                    data-appear-animation="customLineProgressAnim"
                    data-appear-animation-delay={700}
                  />
                </div>
                <p
                  className="font-weight-normal text-3-5 mb-2 appear-animation text-justify pt-3 font"
                  data-appear-animation="fadeInUpShorter"
                  data-appear-animation-delay={450}
                >
                  <span
                    className="fw-bold"
                    style={{ color: "rgb(15, 74, 170)" }}
                  >
                    Sohan Industries,
                  </span>{" "}
                  an ISO 9001:2015 certified company, was established in 2005 by
                  a team of industry experts dedicated to providing professional
                  solutions in the field of metal casting. Since inception, our
                  team has pioneered numerous innovative products and solutions
                  for the foundry and related industries, meeting diverse
                  customer needs and challenges.
                </p>
                <p
                  className="font-weight-normal text-3-5 mb-2 appear-animation text-justify font"
                  data-appear-animation="fadeInUpShorter"
                  data-appear-animation-delay={450}
                >
                  Our primary focus lies in the development and manufacturing of
                  critical and complex products tailored to precise customer
                  requirements. Equipped with state-of-the-art manufacturing
                  facilities, Sohan Industries ensures the production of
                  high-quality products aimed at achieving maximum customer
                  satisfaction.
                </p>
                <p
                  className="font-weight-normal text-3-5 mb-2 appear-animation text-justify font"
                  data-appear-animation="fadeInUpShorter"
                  data-appear-animation-delay={450}
                >
                  At Sohan Industries, we boast a world-class foundry with
                  automated sand plants and cutting-edge digital equipment. Our
                  esteemed clientele includes industries with demands for valve
                  bodies, automotive components, industrial machinery parts, and
                  various other small-scale items. We specialize in designing
                  products that cater to varying requirements in terms of size,
                  weight, criticality, and grades of cast iron. This enables us
                  to undertake challenging casting projects with a weight range
                  spanning from 5kg to 500kg, sizes ranging from 25mm to 500mm,
                  and utilizing various grades of cast iron and SG/ductile iron.
                </p>
                <p
                  className="font-weight-normal text-3-5 mb-2 appear-animation text-justify font"
                  data-appear-animation="fadeInUpShorter"
                  data-appear-animation-delay={450}
                >
                  In addition to our advanced foundry capabilities, we maintain
                  fully equipped machining facilities, ensuring that we deliver
                  castings in impeccably machined conditions.
                </p>
                <div className="row pt-3">
                  <div className="col-sm-5 col-lg-6">
                    <ul className="list list-icons list-icons-style-2 list-icons-lg mb-0 font">
                      <li className="font-weight-semibold text-color-dark">
                        {" "}
                        <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3" />{" "}
                        Perfect finish{" "}
                      </li>
                      <li className="font-weight-semibold text-color-dark">
                        {" "}
                        <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3" />{" "}
                        Corrosion resistance{" "}
                      </li>
                      <li className="font-weight-semibold text-color-dark">
                        {" "}
                        <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3" />{" "}
                        Easy to install{" "}
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-sm-5 col-lg-6 order-lg-2 appear-animation"
                    data-appear-animation="fadeInUpShorterPlus"
                    data-appear-animation-delay={1200}
                  >
                    <ul className="list list-icons list-icons-style-2 list-icons-lg mb-0">
                      <li className="font-weight-semibold text-color-dark">
                        {" "}
                        <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3" />{" "}
                        Dimensional accuracy{" "}
                      </li>
                      <li className="font-weight-semibold text-color-dark">
                        {" "}
                        <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3" />{" "}
                        Sturdiness{" "}
                      </li>
                      <li className="font-weight-semibold text-color-dark">
                        {" "}
                        <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3" />{" "}
                        Competitive Pricing{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/*======================== About SOHANINDUSTRIES END ======================= */}

        {/*======================== Core Ideology END ======================= */}
        {/* <section className="section border-0 m-0 bg5">
          <div className="container my-5 " style={{ marginTop: "200px" }}>
            <div className="row justify-content-center">
              <div className="col-12 text-center pb-5">
                <h2 className="font-weight-bold line-height-1 mb-0 text-color-dark">
                  Our Core Ideology{" "}
                </h2>
                <div className="d-inline-block custom-divider divider divider-primary divider-small my-3">
                  <hr className="my-0" />
                </div>
              </div>
              <div className="col-sm-5 col-lg-3 mb-5 mb-lg-0">
                <div className="core-ideology align-content-center">
                  <div className="icon text-primary text-center">
                    <i className="fa fa-eye" aria-hidden="true" />{" "}
                  </div>
                  <h4 className="text-center pt-3">VISION</h4>
                  <p className="text-center">
                    To be a world class manufacturer in cast iron and SG iron
                    components by 2026 with innovative and global impact at
                    casting industry with achievement of excellence and customer
                    satisfaction. A reliable suppliers of high quality products
                    and a professional orgnaisation with customer oriented
                    services.{" "}
                  </p>
                </div>
              </div>
              <div className="col-sm-5 col-lg-3 mb-5 mb-lg-0">
                <div className="core-ideology align-content-center">
                  <div className="icon text-primary text-center">
                    <i className="fa fa-line-chart" aria-hidden="true" />{" "}
                  </div>
                  <h4 className="text-center pt-3">MISSION</h4>
                  <p className="text-center">
                    Our mission is to be the world class foundry in Cast Iron
                    casting by providing superior services , economical products
                    and projects to customers as per their requirements. Also
                    creating a work culture in which employee can grow, learn
                    and take pride in their accomplishment.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-sm-5 col-lg-3 mb-5 mb-lg-0">
                <div className="core-ideology align-content-center">
                  <div className="icon text-primary text-center">
                    <i className="fa-solid fa-handshake" />{" "}
                  </div>
                  <h4 className="text-center pt-3">VALUES</h4>
                  <ul className="list list-icons list-icons-style-2 list-icons-lg mb-0 list-icon align-content-center text-start">
                    <li className="text-color-dark">
                      {" "}
                      <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3" />{" "}
                      Passionate{" "}
                    </li>
                    <li className="text-color-dark">
                      {" "}
                      <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3" />{" "}
                      Trust{" "}
                    </li>
                    <li className="text-color-dark">
                      {" "}
                      <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3" />{" "}
                      Team work{" "}
                    </li>
                    <li className="text-color-dark pb-3">
                      {" "}
                      <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3" />{" "}
                      Fast innovation{" "}
                    </li>
                  </ul>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <div className="col-sm-5 col-lg-3 mb-5 mb-lg-0">
                <div className="core-ideology align-content-center">
                  <div className="icon text-primary text-center">
                    <i className="fa fa-star" aria-hidden="true" />{" "}
                  </div>
                  <h4 className="text-center pt-3">PURPOSE</h4>
                  <p className="text-center">
                    To be genuine and reliable with customer &amp; also be a
                    part in the growth and journey along with them.
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/*======================== Core Ideology END ======================= */}
{/* 
        <section
          className="parallax section section-text-light section-parallax section-center pt-0 my-0 pb-0"
          data-plugin-parallax
          data-plugin-options="{'speed': 1.5, 'scrollableParallax': true, 'scrollableParallaxMinWidth': 991, 'startOffset': 10, 'cssProperty': 'width', 'cssValueStart': 40, 'cssValueEnd': 100, 'cssValueUnit': 'vw'}"
          data-image-src="swarropalloy/about/about.jpg"
        >
          <div className="d-flex justify-content-center about1-img">
            <h2 style={{ display: "none" }}>Swaroop Alloy</h2>
            <div
              className="scrollable-parallax-wrapper"
              style={{
                backgroundImage: "url(_swarropalloy/about/about.html)",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundAttachment: "fixed",
                transition: "width 200ms ease 0s",
                width: "65vw",
              }}
            >
              <div className="container-fluid py-5 my-5">
                <div className="row justify-content-center py-2 my-5"> </div>
              </div>
            </div>
          </div>
        </section> */}

        {/*==================================	  why choose us Start ===========================================*/}
        {/* <section className="section bg-transparent section-height-4 border-0 m-0 bg4">
          <div className="container">
            <div className="row justify-content-center pb-3 mb-4">
              <div className="col-lg-8 text-center">
                <div className="overflow-hidden">
                  <h2
                    className="font-weight-bold text-color-dark line-height-1 mb-0 appear-animation font"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay={250}
                  >
                    Why Choose Us
                  </h2>
                </div>
                <div
                  className="d-inline-block custom-divider divider  divider-small my-3"
                  style={{}}
                >
                  <hr
                    className="my-0 appear-animation"
                    data-appear-animation="customLineProgressAnim"
                    data-appear-animation-delay={600}
                    style={{ backgroundColor: "rgb(15, 74, 170)" }}
                  />
                </div>
              </div>
            </div>
            <div className="featured-boxes featured-boxes-flat">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="featured-box featured-box-primary featured-box-effect-2">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-tools"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Fast Component Development
                      </h4>
                      <p className="mb-2 mt-3 font">
                        We develop components with a highly experienced and
                        proficient team in quick and optimal lead times.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="featured-box featured-box-primary featured-box-effect-2">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-recycle"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Lean Manufacturing Approach
                      </h4>
                      <p className="mb-2 mt-3 font">
                        Our continuous efforts to reduce waste ensure that
                        customers benefit from cost savings through our lean
                        manufacturing approach.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="featured-box featured-box-primary featured-box-effect-2">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-clock"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        On Time Delivery
                      </h4>
                      <p className="mb-2 mt-3 font">
                        We ensure prompt and on-time delivery according to
                        clients' budgets, specifications, and deadlines.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="featured-box featured-box-primary featured-box-effect-2">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-shield-alt"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Robust QMS Implementation
                      </h4>
                      <p className="mb-2 mt-3 font">
                        As an ISO 9001:2015 certified company, we provide
                        adequate quality assurance to meet customer quality
                        requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 ml-lg-5">
                  <div className="featured-box featured-box-primary featured-box-effect-2 mb-lg-0">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-weight-hanging"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 m-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Flexibility in Size, Weight &amp; Grades
                      </h4>
                      <ul className="list list-icons list-icons-style-2 list-icons-lg mb-2 mt-3 font">
                        <li className="text-color-dark mb-0 font-16 text-start">
                          <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3 " />
                          Weight Range: 2kg – 500kgs
                        </li>
                        <li className="text-color-dark mb-0 font-16 text-start">
                          <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3 " />
                          Size: 25mm - 500mm
                        </li>
                        <li className="text-color-dark mb-0 font-16 text-start">
                          <i className="fas fa-check text-color-dark border-color-grey-1 top-7 text-3 " />
                          We develop various types of CI &amp; SGI Grades
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="featured-box featured-box-primary featured-box-effect-2 mb-lg-0">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-leaf"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Eco-Friendly Infrastructure
                      </h4>
                      <p className="mb-2 mt-3 font">
                        Environmental care is our core value. We have an
                        eco-friendly setup for eliminating waste sand from our
                        foundry and take all reasonable steps to limit
                        environmental damage.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="featured-box featured-box-primary featured-box-effect-2 mb-lg-0">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-people-carry"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Taking Care of All Aspects
                      </h4>
                      <p className="mb-2 mt-3 font">
                        We are honest and professional in our external and
                        internal contacts. We treat the company’s assets,
                        including customers, suppliers, and products, with great
                        respect.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/*==================================	  why choose us End ===========================================*/}

        {/*================================  CTA Start =========================================*/}
        {/* <section className="section section-height-3 border-0 m-0"  style={{ backgroundColor: "rgb(15, 74, 170)" }} >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-xl-7 text-center text-lg-start mb-4 mb-lg-0">
                <h2 className="text-color-light font-weight-medium text-3-5 line-height-2 line-height-sm-1 ls-0 mb-2 mb-lg-2 font">
                  Get Connected With SOHAN INDUSTRIES
                </h2>
                <h3 className="font-weight-bold text-color-light text-transform-none text-8 line-height-2 line-height-lg-1 mb-1 font">
                  Manufacturer of Graded CI &amp; SG Casting
                </h3>                
              </div>
              <div className="col-lg-6 col-xl-5">
                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
                  <div className="feature-box align-items-center mb-3 mb-lg-0">
                    <div className="feature-box-icon bg-transparent">
                      {" "}
                      <i className="icons icon-phone text-6 text-color-light" />{" "}
                    </div>
                    <div className="feature-box-info line-height-2 ps-1">
                      {" "}
                      <span className="d-block text-1 font-weight-semibold text-color-light mb-1 font">
                        CALL US NOW
                      </span>{" "}
                      <strong className="text-4-5">
                        <a
                          href="tel:+919921114376"
                          className="text-color-light text-decoration-none font"
                        >
                          +91 9921114376
                        </a>
                      </strong>{" "}
                    </div>
                  </div>
                  <a
                    href="contact.html"
                    className="btn btn-light btn-outline  custom-btn-border-radius font-weight-bold text-color-light text-color-hover-dark bg-color-hover-light btn-px-5 btn-py-3 font"
                  >
                    CONTACT US
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/*================================  CTA END =========================================*/}


        {/*================================  Production Team Start =========================================*/}
        {/* <section className="shop section bg-transparent section-height-4 border-0 m-0">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center pb-5">
                <h2 className="font-weight-bold line-height-1 mb-0 text-color-dark font">
                  Our Production Team{" "}
                </h2>
                <div className="d-inline-block custom-divider divider divider-primary divider-small my-3">
                  <hr className="my-0" style={{backgroundColor:'rgb(15, 74, 170)'}} />
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 mb-5 mb-lg-0 ">
                <p className="font-weight-normal text-3-5 mb-2 text-justify font">
                  A strong team is the foundation of high performing business
                  and a good team ethic can be held largely accountable for the
                  success and smooth running of the organization. Our young,
                  experience, professional and raring to go teams of over 100
                  employees is consistently trained and upgrade themselves with
                  latest technology trends in foundry and machine industry, and
                  making us a versatile solution provider.
                </p>
                <p className="font-weight-normal text-3-5 mb-2 text-justify pt-3 mb-lg-0 font">
                  We as team communicate openly with each other, sharing
                  thoughts, opinions and ideas with each other which help us to
                  work efficiently on tasks. Our team is passionate and
                  closeknit. We are thrilled to bring our passion to our
                  customers and honouring our commitment of quality, durability
                  and sustainability.
                </p>
              </div>
              <div className="col-lg-6 order-lg-2 text-end">
                <img
                  src={ProductionTeam}
                  className="img-fluid border2"
                  alt
                />
              </div>
            </div>
          </div>
        </section> */}
        {/*================================  Production Team END =========================================*/}
      </div>
    </>
  );
}

export default About;
