import React, { useEffect, useState } from "react";
// import Slider1 from "../../swarropalloy/new/heroIMG1.png";
import Slider2 from "../../swarropalloy/new/Picture2.jpg";
import Slider3 from "../../swarropalloy/new/Picture1.jpg";
import "./home.css";
import demo from "../../swarropalloy/new/About-Home.png";
import Foundry from "../../swarropalloy/new/Foundry-img1.jpg";
import WiproLogo from "../../swarropalloy/new/wipro.jpg";
import UT from "../../swarropalloy/new/UT.png";
import Pennar from "../../swarropalloy/new/Pennar Industries.png";
import Certificates1 from "../../swarropalloy/new/Certification.png";
import product1 from "../../swarropalloy/new/product1.JPEG";
import product2 from "../../swarropalloy/new/product6.jpg";
import product3 from "../../swarropalloy/new/product7.JPEG";
import product4 from "../../swarropalloy/new/product8.JPEG";
import product5 from "../../swarropalloy/new/product11.jpg";
import product6 from "../../swarropalloy/new/product14.JPEG";
import product7 from "../../swarropalloy/new/product17.JPEG";
import product8 from "../../swarropalloy/new/product5.JPEG";
//import apiClient from '../../services/apiService';
import axios from "axios";
import { Carousel, Col, Row, message } from 'antd';


function Home() {
  const [images, setImages] = useState({
    HeroImg1: null,
    HeroImg2: null,
  });
  const [carouselImages, setCarouselImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const baseURL =
  process.env.NODE_ENV === "development"
    ? "https://si-api.sohanindustries.com" // Your local backend URL
    : "https://si-api.sohanindustries.com";

  const api = axios.create({
    baseURL: 'https://si-api.sohanindustries.com',
    timeout: 30000,
    headers: {
      Accept: "application/json",
    },
  });

  const fetchDefaultImages = async () => {
    try {
      const response = await api.get("/heroimgs/1");
      console.log("Fetch response:", response);

      // Create image URLs using the heroimgs/file endpoint
      const imageData = {
        HeroImg1: response.data.HeroImg1
          ? `${baseURL}/uploads/${response.data.HeroImg1}`
          : null,
        HeroImg2: response.data.HeroImg2
          ? `${baseURL}/uploads/${response.data.HeroImg2}`
          : null,
      };
      
      setCarouselImages([imageData]);
    } catch (error) {
      handleApiError(error, "Failed to load default images");
    }
  };

  // const handleImageChange = (e, imageKey) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setImages((prev) => ({
  //       ...prev,
  //       [imageKey]: file,
  //     }));
  //   }
  // };

  const handleApiError = (error, customMessage) => {
    console.error("API Error:", error);
    let errorMessage = customMessage || "An error occurred";
  
    if (error.response) {
      errorMessage += `: ${error.response.status} - ${error.response.statusText}`;
    } else if (error.request) {
      errorMessage += ": No response received";
    } else {
      errorMessage += `: ${error.message}`;
    }
  
    message.error(errorMessage); // Display error notification
  };
  

//   const handleUpload = async () => {
//     if (!images.HeroImg1 || !images.HeroImg2) {
//         message.error('Please select both images');
//         return;
//     }

//     try {
//         setLoading(true);

//         const formData = new FormData();
//         formData.append('HeroImg1', images.HeroImg1);
//         formData.append('HeroImg2', images.HeroImg2);

//         const currentDate = new Date().toISOString();
//         const metadata = {
//             is_default: '1',
//             is_on: '1',
//             is_active: '1',
//             created_by: '1',
//             created_date: currentDate,
//             modified_by: '1',
//             modified_date: currentDate,
//             last_changed: currentDate
//         };

//         Object.entries(metadata).forEach(([key, value]) => {
//             formData.append(key, value);
//         });

//         const response = await api.put('/heroimgs/1', formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data'
//             }
//         });

//         console.log('Upload response:', response);
//         await fetchDefaultImages();
//         message.success('Images uploaded and updated successfully!');
//         resetForm();

//     } catch (error) {
//         handleApiError(error, 'Failed to upload images');
//     } finally {
//         setLoading(false);
//     }
// };

// const resetForm = () => {
//   setImages({
//     HeroImg1: null,
//     HeroImg2: null,
//   });
//   document.querySelectorAll('input[type="file"]').forEach((input) => {
//     input.value = '';
//   });
// };


useEffect(() => {
  fetchDefaultImages();
}, []);


  return (
    <>
      <div role="main" className="main">
        {/* ================= HERO IMG START ==================== */}
        <div
          className="container-fluid z-index-1 appear-animation hero-container"
          data-appear-animation="fadeInDownShorter"
          data-appear-animation-delay={500}
          style={{ opacity: 1 }}
        >
          <div
            className="owl-carousel img-fluid owl-theme full-width owl-loaded owl-drag owl-carousel-init m-0 mb-0 "
            data-plugin-options="{'items': 1, 'loop': true, 'nav': true, 'dots': false, 'animateOut': 'fadeOut'}"
          >
            {/* <div className="hero-text ">
              <h1
                className="hero-title text-white  fw-bold lh-sm"
                style={{
                  fontSize: 48,
                  color: "#0f4AAA",
                  opacity: "1",
                  letterSpacing: "1px",
                }}
              >
                Welcome to <br />
                Sohan Industries!{" "}
              </h1>
              {/* <p className="text-white" style={{ letterSpacing: '1px'}}>We provide products & services of superior quality and <br /> value that improve & satisfy the need of the customers word
              </p>
              <button type="button" className="">Read more</button> 
            </div> */}
            {carouselImages.length > 0 && (
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators align-items-center text-center	d-sm-none d-md-block">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={0}
                    className="active"
                    aria-current="true"
                    aria-label="Slide 2"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={1}
                    aria-label="Slide 3"
                  />
                  {/* <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={2}
                  aria-label="Slide 3"
                /> */}
                </div>

                <div className="carousel-inner">
                  {/* <div className="carousel-item active">
                  <img
                    src={Slider1}
                    className="d-block w-100 mob-img "
                    style={{ height: "520px", marginTop: "121px" }}
                    alt="..."
                  />
                </div> */}
                  {carouselImages[0]?.HeroImg1  && (
                    <div className="carousel-item active">
                      <img
                        src={carouselImages[0].HeroImg1}
                        className="d-block w-100 mob-img"
                        style={{ height: "520px", marginTop: "121px" }}
                        alt="..."
                      />
                    </div>
                  )}
                  {carouselImages[0]?.HeroImg2 && (
                    <div className="carousel-item">
                      <img
                        src={carouselImages[0].HeroImg2}
                        className="d-block w-100 mob-img"
                        style={{ height: "520px", marginTop: "121px" }}
                        alt="..."
                      />
                    </div>
                  )}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            )}
          </div>
        </div>
        {/*==================== HERO IMG END =============== */}

        {/*==================== Contact us Start =============== */}
        <section
          className="section border-0 m-0 appear-animation"
          data-appear-animation="fadeIn"
          data-appear-animation-delay={1200}
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-sm-9 col-md-12 col-lg-9 text-center text-lg-end mb-4 mb-lg-0">
                <h1
                  className="font-weight-bold text-color-primary text-7 line-height-1 mb-1 appear-animation font"
                  data-appear-animation="fadeInLeftShorterPlus"
                  data-appear-animation-delay={1700}
                  style={{ color: "rgb(16 78 161)" }}
                >
                  Graded CI Casting and SG Iron Casting Manufacturer in India
                </h1>
                <p
                  className="font-weight-bold text-4 mb-0 appear-animation font"
                  data-appear-animation="fadeInLeftShorterPlus"
                  data-appear-animation-delay={1900}
                >
                  Sohan Industries is Specialized in Graded CI and SGI Castings
                </p>
              </div>
              <div className="col-lg-3 text-center text-lg-start ps-lg-4">
                {" "}
                {/* <a
                  href="#"
                  className="btn btn-primary  custom-btn-border-radius font-weight-bold text-3 btn-px-5 btn-py-3 ms-lg-2 appear-animation"
                  data-appear-animation="fadeInLeftShorterPlus"
                  data-appear-animation-delay={1500}
                >
                  CONTACT US NOW
                </a>{" "} */}
                <button
                  type="button"
                  style={{ background: "rgb(16 78 161)" }}
                  className="btn btn-primary custom-btn-border-radius font-weight-bold text-3 px-5 btn-py-3 me-sm-2 mb-3 mb-sm-0 appear-animation font"
                >
                  {" "}
                  CONTACT US NOW
                </button>
              </div>
            </div>
          </div>
        </section>
        {/*==================== Contact us ENd =============== */}

        {/*======================== WELCOME TO SOHAN INDUSTRIES  END ======================= */}
        <section className="section border-0 m-0 bg5">
          <div className="container my-5 " style={{ marginTop: "200px" }}>
            <div className="row justify-content-center">
              <div className="col-12 text-center pb-5">
                <h2 className="font-weight-bold line-height-1 mb-0 text-color-dark">
                  WELCOME TO SOHAN INDUSTRIES !{" "}
                </h2>
                <div className="d-inline-block custom-divider divider divider-primary divider-small my-3">
                  <hr className="my-0" />
                </div>
              </div>
              <div className="col-sm-5 col-lg-4 mb-5 mb-lg-0">
                <div className="core-ideology align-content-center">
                  <div className="icon text-primary text-center">
                    <i className="fa fa-gears" aria-hidden="true" />{" "}
                  </div>
                  <h4
                    className="text-center pt-3 tracking-tighter font"
                    style={{ letterSpacing: "0.4px" }}
                  >
                    Engineering Excellence
                  </h4>
                  <p className="text-center font">
                    The process we have been using with our team is focused on
                    gradual improvement. We have team of expertise guys and
                    latest machineries for a rich quality of castings. <br />
                    <br /> <br />
                  </p>
                </div>
              </div>
              <div className="col-sm-5 col-lg-4 mb-5 mb-lg-0">
                <div className="core-ideology align-content-center">
                  <div className="icon text-primary text-center">
                    <i className="fa fa-podcast px-3" aria-hidden="true" />{" "}
                    {/* <img
                    src={Standards}
                    className="border-2 border-radius"                    
                    alt="..."
                  /> */}
                  </div>
                  <h4
                    className="text-center pt-3 font"
                    style={{ letterSpacing: "0.4px" }}
                  >
                    Standards & Ethics
                  </h4>
                  <p className="text-center font">
                    Sohan industries ethics policy reflects the high standard of
                    manufacturing policy our company represents. We dedicate
                    ourselves to anticipating the changing needs of customers
                    and creating quality castings in time.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-sm-5 col-lg-4 mb-5 mb-lg-0">
                <div className="core-ideology align-content-center">
                  <div className="icon text-primary text-center">
                    <i class="fa-solid fa-cloud-sun"></i>{" "}
                  </div>
                  <h4
                    className="text-center pt-3 font"
                    style={{ letterSpacing: "0.4px" }}
                  >
                    Environment
                  </h4>
                  <p className="text-center font">
                    The work environment is very important in determining how
                    enjoyable the work is. We have the best environment among
                    our team of smart guys who have a superior level of
                    intellectual bandwidth and skills as well
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              {/* <div className="col-sm-5 col-lg-3 mb-5 mb-lg-0">
                <div className="core-ideology align-content-center">
                  <div className="icon text-primary text-center">
                    <i className="fa fa-star" aria-hidden="true" />{" "}
                  </div>
                  <h4 className="text-center pt-3">PURPOSE</h4>
                  <p className="text-center">
                    To be genuine and reliable with customer &amp; also be a
                    part in the growth and journey along with them.
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/*======================== WELCOME TO SOHAN INDUSTRIES  END ======================= */}

        {/* =====================================  About Start  ======================================*/}
        <div className="container my-5 pt-md-4 pt-xl-0">
          <div className="row align-items-center justify-content-center pb-4 mb-5">
            <div className="col-lg-6 pb-sm-4 pb-lg-0 mb-5 mb-lg-0">
              <div className="overflow-hidden text-start">
                <h2
                  className="font-weight-bold text-color-dark line-height-1 mb-0 appear-animation font"
                  data-appear-animation="maskUp"
                  data-appear-animation-delay={300}
                >
                  About Company
                </h2>
              </div>
              <div className="custom-divider divider divider-primary divider-small my-3">
                <hr
                  className="my-0 appear-animation"
                  data-appear-animation="customLineProgressAnim"
                  data-appear-animation-delay={700}
                  style={{ backgroundColor: "rgb(16 78 161)" }}
                />
              </div>
              <p
                className="fw-normal text-black text-3-5 mb-5 appear-animation text-justify font"
                data-appear-animation="fadeInUpShorter"
                data-appear-animation-delay={450}
              >
                {" "}
                At &nbsp;
                <span
                  className="fw-bolder "
                  style={{ color: "rgb(15, 74, 170)" }}
                >
                  SOHAN INDUSTRIES,
                </span>{" "}
                we operate a modern facility spread over 13,500 sq. ft., powered
                by a 300 HP connection. Our dedicated team of 60 employees
                utilizes advanced equipment, including a 300 kg Inductotherm
                furnace and 3 centrifugal casting machines, ensuring precision
                and quality in every process.
                <br />
                We are equipped with 2 core shooters, a 30 kg hanger shot
                blasting machine, and multiple fettling, grinding, and molding
                setups. Our efficient production is further supported by a 15 HP
                screw air compressor, cooling tanks, sand mixers, and
                specialized riser cutting machinery. At SOHAN INDUSTRIES, we are
                committed to delivering top-tier industrial casting solutions.
              </p>
              {/* <p
                className="font-weight-light text-3-5 mb-4 appear-animation text-justify"
                data-appear-animation="fadeInUpShorter"
                data-appear-animation-delay={450}
              >
                SOHAN INDUSTRIES, established in 2022-23, builds on over three
                decades of engineering expertise dating back to 1990.
                Specializing in the manufacture of critical engineering parts
                for hydraulic, automobile, sugar, and tooling industries, we
                have expanded our capabilities with a new casting manufacturing
                unit. Our 13,500 sq. ft. facility, powered by a 300 HP
                connection and staffed by 60 skilled employees, is equipped with
                advanced machinery including Inductotherm furnaces, core
                shooters, centrifugal casting machines, and more to ensure
                high-quality production and customer satisfaction.
              </p> */}
              <div className="d-flex align-items-md-start align-items-sm-center flex-column flex-sm-row ">
                {" "}
                <a
                  href="/"
                  className="btn btn-primary custom-btn-border-radius font-weight-bold text-3 px-5 btn-py-3 me-sm-2 mb-3 mb-sm-0 appear-animation font"
                  data-appear-animation="fadeInRightShorterPlus"
                  data-appear-animation-delay={950}
                  style={{ background: "rgb(16 78 161)" }}
                >
                  READ MORE
                </a>
                <div
                  className="feature-box align-items-center border border-top-0 border-end-0 border-bottom-0 custom-remove-mobile-xs-border-left ms-sm-4 ps-sm-4 appear-animation"
                  data-appear-animation="fadeInRightShorterPlus"
                  data-appear-animation-delay={1200}
                >
                  <div className="feature-box-icon bg-transparent align-items-center text-end ABOUT-Phone">
                    {" "}
                    <i className="icons icon-phone text-6 text-color-dark" />{" "}
                  </div>
                  <div className="feature-box-info line-height-2 ps-1 text-start">
                    {" "}
                    <span className="d-block text-1 font-weight-semibold text-color-default font">
                      CALL US NOW
                    </span>{" "}
                    <strong className="text-4-5">
                      <a
                        href="tel:+919921114376"
                        className="text-color-dark text-color-hover-primary text-decoration-none font"
                      >
                        +91 9921114376
                      </a>
                    </strong>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-10 col-md-9 col-lg-6 ps-lg-5 pe-5 appear-animation"
              data-appear-animation="fadeInRightShorterPlus"
              data-appear-animation-delay={1450}
              data-plugin-options="{'accY': -200}"
              style={{ opacity: 1 }}
            >
              <div className="position-relative">
                <div
                  data-plugin-float-element
                  data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}"
                >
                  {" "}
                  <img
                    // src="swarropalloy/home/about.png"
                    src={demo}
                    className="img-fluid w-100"
                    alt="CI Casting and SG Iron Casting"
                  />{" "}
                </div>
                {/* <div
                  className="position-absolute transform3dxy-n50"
                  style={{ top: "25%", left: "7%" }}
                >
                  <div
                    data-plugin-float-element
                    data-plugin-options="{'startPos': 'top', 'speed': 0.5, 'transition': true, 'transitionDuration': 2000, 'isInsideSVG': false}"
                  
                  >
                    {" "}
                    <img
                      src="swarropalloy/home/about3.png"
                      className="appear-animation"
                      alt="CI Casting and SG Iron Casting"
                      data-appear-animation="fadeInUpShorterPlus"
                      data-appear-animation-delay={1700}
                    />{" "}
                  </div>
                </div>
                <div
                  className="position-absolute transform3dxy-n50 ms-5 pb-5 ms-xl-0"
                  style={{ top: "32%", left: "85%" }}
                >
                  <div
                    data-plugin-float-element
                    data-plugin-options="{'startPos': 'top', 'speed': 1, 'transition': true, 'transitionDuration': 1500, 'isInsideSVG': false}"
                  >
                    {" "}
                    <img
                      src="swarropalloy/home/about2.png"
                      className="appear-animation"
                      alt="CI Casting and SG Iron Casting"
                      data-appear-animation="fadeInRightShorterPlus"
                      data-appear-animation-delay={1900}
                    />{" "}
                  </div>
                </div>
                <div
                  className="position-absolute transform3dxy-n50"
                  style={{ top: "90%", left: "19%" }}
                >
                  <div
                    data-plugin-float-element
                    data-plugin-options="{'startPos': 'top', 'speed': 2, 'transition': true, 'transitionDuration': 2500, 'isInsideSVG': false}"
                  >
                    {" "}
                    <img
                      src="swarropalloy/home/about1.png"
                      className="appear-animation"
                      alt="CI Casting and SG Iron Casting"
                      data-appear-animation="fadeInDownShorterPlus"
                      data-appear-animation-delay={2100}
                    />{" "}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* =====================================  About END  ======================================*/}

        {/* =====================================  Company Foundry Capability Start  ======================================*/}
        {/* <section className="section border-0 m-0 pb-0 pt-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 p-0">
                <section
                  className="parallax section section-parallax h-100 m-0 p-0 "
                  data-plugin-parallax
                  data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}"
                  data-image-src={Foundry}
                >
                  {" "}
                  <img className="Foundry-img" alt="" />
                </section>
              </div>
              <div className="col-lg-6 pb-5 pt-5 bg-color-light-scale-4">
                <div className="row">
                  <div className="col-lg-12 pt-5">
                    <div className="heading heading-border heading-middle-border heading-middle-border-center text-center">
                      <h2
                        className=" text-white p-3 rounded"
                        style={{ backgroundColor: "rgb(15, 74, 170) " }}
                      >
                        <span className="font-weight-bold font">
                          Our Foundry Capability
                        </span>
                      </h2>
                    </div>
                  </div>
                  <div className="col-lg-6 p-0">
                    <div className="col-half-section col-half-section-left">
                      <div className="p-3 border-left">
                        <div className="heading heading-border heading-middle-border heading-middle-border-center">
                          <h3 className="font-weight-normal">
                            <strong className="font-weight-bold font">
                              Expertise
                            </strong>
                          </h3>
                        </div>
                        <div className="heading text-primary heading-border heading-bottom-border">
                          <h4 className="font-weight-extra-light font">
                            <i
                              className="fas fa-microchip "
                              style={{ color: "rgb(15, 74, 170)" }}
                            />{" "}
                            &nbsp;Industry Knowledge
                          </h4>
                        </div>
                        <div className="heading text-primary heading-border heading-bottom-border pb-4">
                          <h4 className="font-weight-extra-light font">
                            <i
                              className="fas fa-cogs "
                              style={{ color: "rgb(15, 74, 170)" }}
                            />{" "}
                            &nbsp;Track Record
                          </h4>
                        </div>
                        <div className="heading heading-border heading-middle-border heading-middle-border-center">
                          <h3 className="font-weight-normal font">
                            <strong className="font-weight-bold ">
                              Quality
                            </strong>
                          </h3>
                        </div>
                        <div className="heading text-primary heading-border heading-bottom-border">
                          <h4 className="font-weight-extra-light font">
                            <i
                              className="fas fa-check-circle "
                              style={{ color: "rgb(15, 74, 170)" }}
                            />{" "}
                            &nbsp;High Standards
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 p-0">
                    <div className="col-half-section col-half-section-right">
                      <div className="p-3 border-left">
                        <div className="heading heading-border heading-middle-border heading-middle-border-center">
                          <h3 className="font-weight-normal font">
                            <strong className="font-weight-bold ">
                              Global Reach
                            </strong>
                          </h3>
                        </div>
                        <div className="heading text-primary heading-border heading-bottom-border">
                          <h4 className="font-weight-extra-light font">
                            <i
                              className="fas fa-globe "
                              style={{ color: "rgb(15, 74, 170)" }}
                            />{" "}
                            &nbsp;Wide Network
                          </h4>
                        </div>
                        <div className="heading text-primary heading-border heading-bottom-border pb-4">
                          <h4 className="font-weight-extra-light font">
                            <i
                              className="fas fa-map-marker-alt "
                              style={{ color: "rgb(15, 74, 170)" }}
                            />{" "}
                            &nbsp;Local Presence
                          </h4>
                        </div>
                        <div className="border-left">
                          <div className="heading heading-border heading-middle-border heading-middle-border-center">
                            <h3 className="font-weight-normal font">
                              <strong className="font-weight-bold">
                                Strong Partnerships
                              </strong>
                            </h3>
                          </div>
                          <div className="heading text-primary heading-border heading-bottom-border">
                            <h4 className="font-weight-extra-light font">
                              <i
                                className="fas fa-handshake"
                                style={{ color: "rgb(15, 74, 170)" }}
                              />{" "}
                              &nbsp;Industry Alliances
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* =====================================  Company Foundry Capability END  ======================================*/}

        {/* =====================================  VISION & MISSION Start  ======================================*/}
        <section className="section border-0 m-0 pb-0 pt-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 p-0">
                <section
                  className="parallax section section-parallax h-100 m-0 p-0 "
                  data-plugin-parallax
                  data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}"
                  data-image-src={Foundry}
                >
                  {" "}
                  <img className="Foundry-img" alt="" />
                </section>
              </div>
              <div className="col-lg-6 pb-5 pt-5 bg-color-light-scale-4">
                <div className="row">
                  <div className="col-lg-12 ">
                    <div className="heading heading-border heading-middle-border heading-middle-border-center text-center">
                      <h2
                        className=" text-white p-3 rounded"
                        style={{
                          backgroundColor: "rgb(15, 74, 170)  ",
                          letterSpacing: "0.5px",
                        }}
                      >
                        <span className="font-weight-bold font">
                          VISION & MISSION
                        </span>
                      </h2>
                    </div>
                  </div>
                  <div className="col-lg-12 p-0">
                    <div className="col-half-section col-half-section-center ">
                      <p
                        className="fw-normal text-black text-3-5  appear-animation text-justify font"
                        data-appear-animation="fadeInUpShorter"
                        data-appear-animation-delay={450}
                      >
                        <span
                          className="fw-bolder "
                          style={{
                            color: "rgb(15, 74, 170)",
                            letterSpacing: "0.5px",
                          }}
                        >
                          VISION :
                        </span>{" "}
                        ‘To be a global player in the provision of manufacturer
                        and supply of machined components.’
                        <br /> <br />
                        <span
                          className="fw-bolder "
                          style={{
                            color: "rgb(15, 74, 170)",
                            letterSpacing: "0.5px",
                          }}
                        >
                          MISSION STATEMENT :
                        </span>{" "}
                        We promise to deliver world class quality products and
                        services that exceed customer expectations while earning
                        equitable returns needed to sustain our welfare and
                        environmental surroundings.
                        <br /> <br />
                        <span
                          className="fw-bolder "
                          style={{
                            color: "rgb(15, 74, 170)",
                            letterSpacing: "0.5px",
                          }}
                        >
                          Values :
                        </span>{" "}
                        We will provide products and services of superior
                        quality and value that imporve and satisifes the needs
                        of the worlds customers.
                        <br /> <br />
                        <span
                          className="fw-bolder "
                          style={{
                            color: "rgb(15, 74, 170)",
                            letterSpacing: "0.5px",
                          }}
                        >
                          FUTURE SCOPE :
                        </span>{" "}
                        We are looking for purchasing new Sand plant Casting
                        Method at our premises in future. Now we are looking for
                        under taking 5 S system for implementation.
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* =====================================  VISION & MISSION END  ======================================*/}

        {/*==================================	  why choose us Start ===========================================*/}
        <section className="section bg-transparent section-height-4 border-0 m-0 bg4 ">
          <div className="container mb-5">
            <div className="row justify-content-center pb-3 mb-4">
              <div className="col-lg-8 text-center">
                <div className="overflow-hidden">
                  <h2
                    className="font-weight-bold text-color-dark line-height-1 mb-0 appear-animation font"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay={250}
                  >
                    Why Choose Us
                  </h2>
                </div>
                <div
                  className="d-inline-block custom-divider divider  divider-small my-3"
                  style={{}}
                >
                  <hr
                    className="my-0 appear-animation"
                    data-appear-animation="customLineProgressAnim"
                    data-appear-animation-delay={600}
                    style={{ backgroundColor: "rgb(15, 74, 170)" }}
                  />
                </div>
              </div>
            </div>
            <div className="featured-boxes featured-boxes-flat">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="featured-box featured-box-primary featured-box-effect-2">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-tools"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Advanced Technology
                      </h4>
                      <p className="mb-2 mt-3 font">
                        We use cutting-edge equipment for precise results.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="featured-box featured-box-primary featured-box-effect-2">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-clock"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Unwavering Quality
                      </h4>
                      <p className="mb-2 mt-3 font">
                        Rigorous quality control ensures top-notch products.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="featured-box featured-box-primary featured-box-effect-2">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-shield-alt"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Sustainability Focus
                      </h4>
                      <p className="mb-2 mt-3 font">
                        Eco-friendly practices reduce waste and energy use.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="featured-box featured-box-primary featured-box-effect-2">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-recycle"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Customized Solutions
                      </h4>
                      <p className="mb-2 mt-3 font">
                        Tailored strategies to meet your unique needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 ml-lg-5">
                  <div className="featured-box featured-box-primary featured-box-effect-2 mb-lg-0">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-weight-hanging"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 m-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        On-Time Delivery
                      </h4>
                      <p className="mb-2 mt-3 font">
                        Efficient processes guarantee punctual project
                        completion.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="featured-box featured-box-primary featured-box-effect-2 mb-lg-0">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-leaf"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Competitive Pricing
                      </h4>
                      <p className="mb-2 mt-3 font">
                        Transparent and cost-effective pricing for maximum
                        value.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 ">
                  <div className="featured-box featured-box-primary featured-box-effect-2 mb-lg-0">
                    <div className="box-content box-content-border-bottom">
                      <i
                        className="icon-featured fas fa-people-carry"
                        style={{ backgroundColor: "rgb(15, 74, 170)" }}
                      />
                      <h4
                        className="font-weight-bold text-5 mt-0 font"
                        style={{ color: "rgb(15, 74, 170)" }}
                      >
                        Exceptional Support
                      </h4>
                      <p className="mb-2 mt-3 font">
                        Dedicated customer service from start to finish.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center p-0 m-0">
                  <div className="col-lg-3 col-sm-6 ">
                    <div className="featured-box featured-box-primary featured-box-effect-2 mb-lg-0">
                      <div className="box-content box-content-border-bottom">
                        <i
                          className="icon-featured fas fa-people-carry"
                          style={{ backgroundColor: "rgb(15, 74, 170)" }}
                        />
                        <h4
                          className="font-weight-bold text-5 mt-0 font"
                          style={{ color: "rgb(15, 74, 170)" }}
                        >
                          Proven Success
                        </h4>
                        <p className="mb-2 mt-3 font">
                          A strong track record of satisfied clients and
                          successful projects.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="featured-box featured-box-primary featured-box-effect-2 mb-lg-0">
                      <div className="box-content box-content-border-bottom">
                        <i
                          className="icon-featured fas fa-people-carry"
                          style={{ backgroundColor: "rgb(15, 74, 170)" }}
                        />
                        <h4
                          className="font-weight-bold text-5 mt-0 font"
                          style={{ color: "rgb(15, 74, 170)" }}
                        >
                          Innovative Approach
                        </h4>
                        <p className="mb-2 mt-3 font">
                          Constantly exploring new techniques for enhanced
                          performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*==================================	  why choose us End ===========================================*/}

        {/*=================================  Our Products Start  =========================================*/}
        <section className="shop section bg-transparent section-height-4 border-0 m-0">
          <div className="container">
            <div className="row justify-content-center pb-3 mb-4">
              <div className="col-lg-8 text-center">
                <div className="overflow-hidden">
                  <h2
                    className="font-weight-bold text-color-dark line-height-1 mb-0 appear-animation font"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay={250}
                  >
                    Discover Our Products
                  </h2>
                </div>
                <div
                  className="d-inline-block custom-divider divider divider-primary divider-small my-3"
                  style={{ backgroundColor: "rgb(15, 74, 170)" }}
                >
                  <hr
                    className="my-0 appear-animation"
                    data-appear-animation="customLineProgressAnim"
                    data-appear-animation-delay={600}
                  />
                </div>
                <p
                  className="font-weight-light text-4 mb-0 appear-animation font"
                  data-appear-animation="fadeInUpShorter"
                  data-appear-animation-delay={500}
                >
                  Explore our diverse range of high-quality products designed to
                  meet specific customer requirements. Our state-of-the-art
                  manufacturing facilities ensure excellence in every detail.
                </p>
              </div>
            </div>
            <div
              className="products row row-gutter-sm mb-4 appear-animation"
              data-appear-animation="fadeInUpShorter"
              data-appear-animation-delay={750}
            >
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Delval Casting"
                      className="img-fluid border2"
                      src={product1}
                      style={{ height: "300px", width: "250px" }}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Crane PN 10 Series CI Old"
                      className="img-fluid border2"
                      src={product2}
                      style={{ height: "300px", width: "250px" }}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Crane Spring Plate Lower 65 (DI)"
                      className="img-fluid border2"
                      src={product3}
                      style={{ height: "300px", width: "250px" }}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Lotus Bracket YN02P01398 (Core)"
                      className="img-fluid border2"
                      src={product4}
                      style={{ height: "300px", width: "250px" }}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product ROTEX FRONT COVER 200"
                      className="img-fluid border2"
                      src={product5}
                      style={{ height: "300px", width: "250px" }}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Lotus Bracket YN02P01409 P1"
                      className="img-fluid border2"
                      src={product6}
                      style={{ height: "300px", width: "250px" }}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Crane Std. Neck Casting"
                      className="img-fluid border2"
                      src={product7}
                      style={{ height: "300px", width: "250px" }}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product GPI PO Name WHEEL HUB 365"
                      className="img-fluid border2"
                      src={product8}
                      style={{ height: "300px", width: "250px" }}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col text-center">
                {" "}
                <a
                  href="/"
                  className="btn btn-primary custom-btn-border-radius font-weight-bold text-3 btn-px-5 btn-py-3 appear-animation font"
                  data-appear-animation="fadeInUpShorterPlus"
                  data-appear-animation-delay={800}
                  style={{ backgroundColor: "rgb(15, 74, 170)" }}
                >
                  VIEW ALL PRODUCTS
                </a>{" "}
              </div>
            </div> */}
          </div>
        </section>
        <section className="Directors-image d-grid gap-3 ">
          <div class="Directors-text w-100  p-5 mt-5 mb-5">
            <h1
              className="text-white"
              style={{ "font-size": "50px", letterSpacing: "0.5px" }}
            >
              SUNIL JAGJAMPI{" "}
            </h1>
            <p className="text-white" style={{ letterSpacing: "0.5px" }}>
              In the foundry industry, quality is paramount. It ensures the
              durability, precision, and reliability of cast metal products.
              From meticulous material selection to stringent testing and
              quality control processes, maintaining high standards is essential
              to producing components that meet or exceed specifications.
              Superior quality in foundry work not only enhances performance but
              also ensures longevity and customer satisfaction, setting the
              foundation for trust and excellence in every project.
            </p>
          </div>
        </section>
        {/*=================================  Our Products END  =========================================*/}

        {/*============================ Awards and Recognition Start =============================*/}
        <section className="section bg-transparent section-height-4 border-0 m-0 bg1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center pb-4">
                  <h2 className="font-weight-bold text-color-dark line-height-1 mb-0 font">
                    THE COMPANY IS CERTIFIED BY FOLLOWING AGENCY{" "}
                  </h2>
                  <div
                    className="d-inline-block custom-divider divider  divider-small my-3"
                    style={{ backgroundColor: "rgb(15, 74, 170)" }}
                  >
                    <hr
                      className="my-0"
                      style={{ backgroundColor: "rgb(15, 74, 170)" }}
                    />
                  </div>
                  <h4 className="font-weight-normal text-color-dark line-height-1 mb-0 font">
                    Certification and standards of Sohan industry
                  </h4>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    {" "}
                    <span className="d-block text-center mb-5 mb-lg-0">
                      {" "}
                      <img
                        className="img-fluid border2"
                        src={Certificates1}
                        alt="Certificates"
                      />{" "}
                    </span>{" "}
                  </div>
                  {/* <div className="col-lg-6">
                    {" "}
                    <span className=" d-block text-center mb-5 mb-lg-0">
                      {" "}
                      <img
                        className="img-fluid"
                        src={Certificates2}
                        alt="Certificates"
                      />{" "}
                    </span>{" "}
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="row pt-5">
              <div className="col-lg-3 text-center mb-5 mb-lg-0">
                {" "}
                <img
                  className="img-fluid img1"
                  src={Certificates4}
                  alt="Awards"
                />
              </div>
              <div className="col-lg-3 text-center mb-5 mb-lg-0">
                {" "}
                <img
                  className="img-fluid img1"
                  src={Certificates4}
                  alt="Awards"
                />{" "}
              </div>
              <div className="col-lg-3 text-center mb-5 mb-lg-0">
                {" "}
                <img
                  className="img-fluid img1"
                  src={Certificates6}
                  alt="Awards"
                />{" "}
              </div>
              <div className="col-lg-3 text-center mb-5 mb-lg-0">
                <img
                  className="img-fluid img2"
                  src={Certificates5}
                  alt="Awards"
                />
              </div>
            </div> */}
          </div>
        </section>
        {/*============================ Awards and Recognition END =============================*/}

        {/*================================  CTA Start =========================================*/}
        <section
          className="section section-height-3 border-0 m-0"
          style={{ backgroundColor: "rgb(15, 74, 170)" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-xl-7 text-center text-lg-start mb-4 mb-lg-0">
                <h2 className="text-color-light font-weight-medium text-3-5 line-height-2 line-height-sm-1 ls-0 mb-2 mb-lg-2 font">
                  Get Connected With SOHAN INDUSTRIES
                </h2>
                <h3 className="font-weight-bold text-color-light text-transform-none text-8 line-height-2 line-height-lg-1 mb-1 font">
                  Manufacturer of Graded CI &amp; SG Casting
                </h3>
                {/*								<p class="font-weight-bold text-color-light text-4 opacity-7 mb-0">Get Connected With Swaroop Alloy Pvt. Ltd.</p>*/}
              </div>
              <div className="col-lg-6 col-xl-5">
                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
                  <div className="feature-box align-items-center mb-3 mb-lg-0">
                    <div className="feature-box-icon bg-transparent">
                      {" "}
                      <i className="icons icon-phone text-6 text-color-light" />{" "}
                    </div>
                    <div className="feature-box-info line-height-2 ps-1">
                      {" "}
                      <span className="d-block text-1 font-weight-semibold text-color-light mb-1 font">
                        CALL US NOW
                      </span>{" "}
                      <strong className="text-4-5">
                        <a
                          href="tel:+919921114376"
                          className="text-color-light text-decoration-none font"
                        >
                          +91 9921114376
                        </a>
                      </strong>{" "}
                    </div>
                  </div>
                  <a
                    href="/"
                    className="btn btn-light btn-outline  custom-btn-border-radius font-weight-bold text-color-light text-color-hover-dark bg-color-hover-light btn-px-5 btn-py-3 font"
                  >
                    CONTACT US
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*================================  CTA Start =========================================*/}

        {/*=======================   Happy customers Start ================================*/}
        <section className="section bg-transparent section-height-4 position-relative border-0 z-index-1 m-0 ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col text-center">
                <h2 className="font-weight-bold text-color-dark line-height-1 mb-0 font">
                  Our Happy customers{" "}
                </h2>
                <div className="d-inline-block custom-divider divider  divider-small my-1">
                  <hr
                    className="my-0"
                    style={{ backgroundColor: "rgb(15, 74, 170)" }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xl-3 col-md-3 col-sm-12  mb-2 mt-2 align-items-center text-center ">
                {" "}
                <img
                  src={WiproLogo}
                  alt="DELVAL FLOW CONTROL PVT LTD"
                  className="img-fluid border1 "
                  // style={{width:"400px"}}
                />{" "}
                <h4
                  className="font-weight-bold text-5 mt-3 font "
                  style={{ color: "rgb(15, 74, 170)" }}
                >
                  Wipro Infrastructure Engineering Pvt Ltd
                </h4>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-12  mb-2 mt-2  align-items-center text-center">
                {" "}
                <img
                  src={UT}
                  alt="CRANE PROCESS FLOW TECHNOLOGIES"
                  className="img-fluid border1 "
                  style={{ height: "190px", width: "230px" }}
                />{" "}
                <h4
                  className="font-weight-bold text-5 mt-3 font"
                  style={{ color: "rgb(15, 74, 170)" }}
                >
                  Unitech Cnc Centre <br /> india Pvt.Ltd
                </h4>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-12 mt-2 mb-2 align-items-center text-center">
                {" "}
                <img
                  src={Pennar}
                  style={{ height: "190px", width: "100%" }}
                  alt="GHATAGE PATIL INDUSTRIES PVT LTD"
                  className="img-fluid border1 "
                />{" "}
                <h4
                  className="font-weight-bold text-5 mt-3 font"
                  style={{ color: "rgb(15, 74, 170)" }}
                >
                  Pennar Industries
                </h4>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-12 mt-2 mb-2 align-items-center text-center d-flex justify-content-center">
                {/* {" "}
                <img
                  src={WiproLogo}
                  // style={{ height: "235px", width: "250px" }}
                  alt="GHATAGE PATIL INDUSTRIES PVT LTD"
                  className="img-fluid border1"
                />{" "} */}
                <h2
                  className="font-weight-bold text-5 mt-3 font"
                  style={{ color: "rgb(15, 74, 170)" }}
                >
                  Shiv shakti <br /> machine tools,
                  <br /> Kolhapur
                </h2>
              </div>
              {/* <div className="col-xl-2 col-md-4 col-6 mb-5">
                {" "}
                <img
                  src="swarropalloy/customers/04-tata-hendrickson.jpg"
                  alt="TATA AUTOCOMP HENDRICKSON SUSPENSION PVT LTD"
                  className="img-fluid border1"
                />{" "}
              </div>
              <div className="col-xl-2 col-md-4 col-6 mb-5">
                {" "}
                <img
                  src="swarropalloy/customers/05-abhijaat-equipments.jpg"
                  alt="ABHIJAAT EQUIPMENTS PVT LTD"
                  className="img-fluid border1"
                />{" "}
              </div>
              <div className="col-xl-2 col-md-4 col-6 mb-5 ">
                {" "}
                <img
                  src="swarropalloy/customers/06-laxmi-oil.jpg"
                  alt="LAXMI OIL PUMPS & SYSTEMS PVT. LTD."
                  className="img-fluid border1"
                />{" "}
              </div>
              <div className="col-xl-2 col-md-4 col-6 mb-5 mb-lg-0">
                {" "}
                <img
                  src="swarropalloy/customers/07-qualitas.jpg"
                  alt="QUALITAS EXPORTS PVT LTD"
                  className="img-fluid border1"
                />{" "}
              </div>
              <div className="col-xl-2 col-md-4 col-6 mb-5 mb-lg-0">
                {" "}
                <img
                  src="swarropalloy/customers/08-valmet.jpg"
                  alt="NELES INDIA PRIVATE LIMITED (PART OF VALMET)"
                  className="img-fluid border1"
                />{" "}
              </div>
              <div className="col-xl-2 col-md-4 col-6 mb-5 mb-lg-0">
                {" "}
                <img
                  src="swarropalloy/customers/09-lotus.jpg"
                  alt="LOTUS ENGINEERING"
                  className="img-fluid border1"
                />{" "}
              </div>
              <div className="col-xl-2 col-md-4 col-6 mb-5 mb-lg-0">
                {" "}
                <img
                  src="swarropalloy/customers/10-shakti-pumps.jpg"
                  alt="SHAKTI PUMP"
                  className="img-fluid border1"
                />{" "}
              </div> */}
            </div>
          </div>
          <svg
            className="custom-svg-3"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 193 495"
          >
            <path
              fill="rgb(15, 74, 170)"
              d="M193,25.73L18.95,247.93c-13.62,17.39-10.57,42.54,6.82,56.16L193,435.09V25.73z"
            />
            <path
              fill="none"
              stroke="#FFF"
              strokeWidth="1.5"
              strokeMiterlimit={10}
              d="M196,53.54L22.68,297.08c-12.81,18-8.6,42.98,9.4,55.79L196,469.53V53.54z"
            />
          </svg>
        </section>
        {/*=======================   Happy customers End ================================*/}
      </div>
    </>
  );
}

export default Home;
