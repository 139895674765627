import React from 'react'

function Career() {
  return (
    <div className='' style={{height:'600px'}}>
      <h1 className=''>
      Career
      </h1>
      </div>
  )
}

export default Career