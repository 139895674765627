import React from 'react'
import product1 from "../../swarropalloy/new/product1.JPEG";
import product2 from "../../swarropalloy/new/product6.jpg";
import product3 from "../../swarropalloy/new/product7.JPEG";
import product4 from "../../swarropalloy/new/product8.JPEG";
import product5 from "../../swarropalloy/new/product11.jpg";
import product6 from "../../swarropalloy/new/product14.JPEG";
import product7 from "../../swarropalloy/new/product17.JPEG";
import product8 from "../../swarropalloy/new/product5.JPEG";


function Products() {
  return (
    <>
      <div role="main" className="main">
  <section className="page-header page-header-modern page-header-lg bg3 mb-0">
    <div className="container " style={{marginTop:'250px'}}> 
      <div className="row">
        <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
          <h1 className="text-color-white font-weight-bold mb-0">Products</h1>
        </div>
        <div className="col-md-4 order-1 order-md-2 align-self-center">
          <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
            <li><a href="index.html" className="text-color-white text-color-hover-white text-decoration-none">HOME</a></li>
            <li className="active text-white">Products</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  {/*=================================  Our Products Start  =========================================*/}
  <section className="shop section bg-transparent section-height-4 border-0 m-0">
          <div className="container">
            <div className="row justify-content-center pb-3 mb-4">
              <div className="col-lg-8 text-center">
                <div className="overflow-hidden">
                  <h2
                    className="font-weight-bold text-color-dark line-height-1 mb-0 appear-animation font"
                    data-appear-animation="maskUp"
                    data-appear-animation-delay={250}
                  >
                    Discover Our Products
                  </h2>
                </div>
                <div
                  className="d-inline-block custom-divider divider divider-primary divider-small my-3"
                  style={{ backgroundColor: "rgb(15, 74, 170)" }}
                >
                  <hr
                    className="my-0 appear-animation"
                    data-appear-animation="customLineProgressAnim"
                    data-appear-animation-delay={600}
                  />
                </div>
                <p
                  className="font-weight-light text-4 mb-0 appear-animation font"
                  data-appear-animation="fadeInUpShorter"
                  data-appear-animation-delay={500}
                >
                  Explore our diverse range of high-quality products designed to
                  meet specific customer requirements. Our state-of-the-art
                  manufacturing facilities ensure excellence in every detail.
                </p>
              </div>
            </div>
            <div
              className="products row row-gutter-sm mb-4 appear-animation"
              data-appear-animation="fadeInUpShorter"
              data-appear-animation-delay={750}
            >
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3" >
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Delval Casting"
                      className="img-fluid border2"
                      src={product1}
                      style={{height: "300px", width: "250px"}}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Crane PN 10 Series CI Old"
                      className="img-fluid border2"
                      src={product2}
                      style={{height: "300px", width: "250px"}}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Crane Spring Plate Lower 65 (DI)"
                      className="img-fluid border2"
                      src={product3}
                      style={{height: "300px", width: "250px"}}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Lotus Bracket YN02P01398 (Core)"
                      className="img-fluid border2"
                      src={product4}
                      style={{height: "300px", width: "250px"}}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product ROTEX FRONT COVER 200"
                      className="img-fluid border2"
                      src={product5}
                      style={{height: "300px", width: "250px"}}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Lotus Bracket YN02P01409 P1"
                      className="img-fluid border2"
                      src={product6}
                      style={{height: "300px", width: "250px"}}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product Crane Std. Neck Casting"
                      className="img-fluid border2"
                      src={product7}
                      style={{height: "300px", width: "250px"}}
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                  <div className="text-center mb-3">
                    {" "}
                    <img
                      alt="Swaroop Alloy Product GPI PO Name WHEEL HUB 365"
                      className="img-fluid border2"
                      src={product8}
                      style={{height: "300px", width: "250px"}}
                    />{" "}
                  </div>
                </div>
              </div>
              
            </div>
            {/* <div className="row">
              <div className="col text-center">
                {" "}
                <a
                  href="/"
                  className="btn btn-primary custom-btn-border-radius font-weight-bold text-3 btn-px-5 btn-py-3 appear-animation font"
                  data-appear-animation="fadeInUpShorterPlus"
                  data-appear-animation-delay={800}
                  style={{ backgroundColor: "rgb(15, 74, 170)" }}
                >
                  VIEW ALL PRODUCTS
                </a>{" "}
              </div>
            </div> */}
          </div>
        </section>


  {/*Our Products  */}
  {/* <section className="shop section bg-transparent section-height-4 border-0 m-0">
    <div className="container">
      <div className="row justify-content-center pb-5 mb-4">
        <div className="col-lg-12 text-center"> 
          <h2 style={{display: 'none'}}>Swaroop Alloy</h2>
          <p className="font-weight-light text-3-5 mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay={500}>We focus mainly on developing and manufacturing critical and challenging products according to specific requirements of customers. Our manufacturing plants are equipped with the latest amenities to ensure that we produce high-quality products aimed at delivering customer satisfaction.</p>
        </div>
      </div>
      <div className="products row row-gutter-sm mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay={750}>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Delval Casting" className="img-fluid border2" src="swarropalloy/products/01-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Crane PN 10 Series CI Old" className="img-fluid border2" src="swarropalloy/products/02-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Crane Spring Plate Lower 65 (DI)" className="img-fluid border2" src="swarropalloy/products/03-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Delval 41 Series Delval" className="img-fluid border2" src="swarropalloy/products/04-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product ROTEX FRONT COVER 200" className="img-fluid border2" src="swarropalloy/products/10-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Lotus Bracket YN02P01409 P1" className="img-fluid border2" src="swarropalloy/products/08-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product GPI PO Name WHEEL HUB 365" className="img-fluid border2" src="swarropalloy/products/07-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Qualitas IDLER GEAR 15 D 14" className="img-fluid border2" src="swarropalloy/products/09-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Qualitas OUTPUT GEAR IS 15 D 13-16 D 13 (DI)" className="img-fluid border2" src="swarropalloy/products/11-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Delval 58 Series Double Flange" className="img-fluid border2" src="swarropalloy/products/12-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Crane  Ansi Replace 50 to 300" className="img-fluid border2" src="swarropalloy/products/13-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy ProductDelval 52 Series 8 Lug Body DI" className="img-fluid border2" src="swarropalloy/products/05-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Crane Disc PN 10 DN 50 DI" className="img-fluid border2" src="swarropalloy/products/14-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Delval 40 Series (Core)" className="img-fluid border2" src="swarropalloy/products/15-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Lotus Bracket YN02P01398 (Core)" className="img-fluid border2" src="swarropalloy/products/18-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Rotex Piston Cover 200 (Core)" className="img-fluid border2" src="swarropalloy/products/19-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Delval 52 Series Lug Body" className="img-fluid border2" src="swarropalloy/products/06-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Crane Std. Neck Casting" className="img-fluid border2" src="swarropalloy/products/20-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2 mb-lg-0">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product Delval Casting Photos (2)" className="img-fluid border2" src="swarropalloy/products/16-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2 mb-lg-0">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product GPI Daiphram Plate Casting Show P-NYMD-013560 REY-A2" className="img-fluid border2" src="swarropalloy/products/17-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2 mb-lg-0">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product GPI Daiphram Plate Casting Show P-NYMD-013560 REY-A2" className="img-fluid border2" src="swarropalloy/products/21-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2 mb-lg-0">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product GPI Daiphram Plate Casting Show P-NYMD-013560 REY-A2" className="img-fluid border2" src="swarropalloy/products/22-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2 mb-lg-0">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product GPI Daiphram Plate Casting Show P-NYMD-013560 REY-A2" className="img-fluid border2" src="swarropalloy/products/23-product.jpg" /> </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-2 mb-lg-0">
          <div className="product mb-0">
            <div className="text-center mb-3"> <img alt="Swaroop Alloy Product GPI Daiphram Plate Casting Show P-NYMD-013560 REY-A2" className="img-fluid border2" src="swarropalloy/products/24-product.jpg" /> </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  {/*Our Products  */}
</div>

    </>
  )
}

export default Products